/* global Component, _ */
class favouriteOrderDetailModalComponent extends Component {

    static name() {
        return "favouriteOrderDetailModalComponent";
    }

    static componentName() {
        return "favouriteOrderDetailModalComponent";
    }

    data() {
        return {
            message:null,
        };
    }

    getComputed() {
        return {
            currentfavOrder: function () {
                return this.$store.getters.getCurrentFavOrder;
            },
            FavOrder: function () {
                this.message = null;
                if (this.currentfavOrder){
                    let notFoundItems = 0;
                    let items = [];
                    for (let [index, item] of this.currentfavOrder[1].entries()) {
                        const storeItem = _.cloneDeep(this.$store.getters.getItemByCode(item[0]));
                        if(storeItem) {
                            storeItem.index = index;
                            storeItem.carQyt = item[1];
                            items.push(storeItem);
                        } else {
                            notFoundItems += 1;
                        }
                    }
                    if(notFoundItems > 0)
                        this.message = [`{{$1}} item was omitted from this order`, notFoundItems];

                    return {Name:this.currentfavOrder[0],Items:items};
                }
                return {};
            },
        };
    }

    getMethods() {
        return {
            sendOrdertoCar:this.sendOrdertoCar,
            getItemName:this.getItemName,
            addItemToCar: this.addItemToCar,
            getMaxStockByCode: this.getMaxStockByCode,
            updateQty:this.updateQty,
        };
    }

    addItemToCar(item,reloadCard = false){
        if(isNaN(item.carQyt) || parseInt(item.carQyt) == 0) {
            this.alertShowMessage(`Incorrent Qty Value`);
            return;
        }
        const storeItem = _.cloneDeep(this.$store.getters.getItemByCode(item.Code));
        if(this.controlStock) {
            let itemInCar = this.$store.getters.getItemCar.filter(x => x.id == item.Code)[0];
            const maxQty = this.getMaxStockByCode(item.Code);
            if (itemInCar) {
                if (parseInt(itemInCar.cant) + parseInt(item.carQyt) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
            else{
                if (parseInt(item.carQyt) > maxQty) {
                    if(maxQty === 0)
                        this.alertShowMessage("ITEM_STOCK_ZERO");
                    else
                        this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                    return;
                }
            }
        }

        if(reloadCard)
            this.$store.dispatch('ItemComponentAddToCar', storeItem);
        else
            this.$store.commit('updateItemToCar', storeItem);
    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(this.$store.getters.getSetting.ControlStockForSales &&  !(giftCardItem && giftCardItem.fields.Code == itemCode)) {
            let itemInStore = this.$store.getters.getItemByCode(itemCode);
            const qtyInCar = this.$store.getters.getItemAmountInCar(itemCode);
            if(itemInStore.Reserved)
                return itemInStore.Qty - itemInStore.Reserved - qtyInCar;
            return itemInStore.Qty - qtyInCar;
        }
        return this.$store.getters.getMaxQtyItem - this.$store.getters.getItemQtyCar(itemCode);
    }

    updateQty(event, item){
        const {target} = event;
        const position = this.currentfavOrder[1].findIndex(x=> x[0] === item.Code);
        if(position !== -1)
            this.currentfavOrder[1][position][1] = target.value;
    }

    sendOrdertoCar() {
        const items = Array.from(this.FavOrder.Items);
        if(items.length > 1)
            items.slice(0, -1).forEach(x=>this.addItemToCar(x));
        if(items.length > 0)
            items.slice(-1).forEach(x=>this.addItemToCar(x, true));
    }

    getItemName(ArtCode){
        let item = this.$store.getters.getItemByCode(ArtCode);
        if(item)
            return item.Name;
        return "ITEM NOT FOUND";
    }

    getTemplate() {
        return `<div class="modal fade item-detail-modal" id="favOrderDetailModal" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Favorite Order Items")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body" v-if="FavOrder">
                            <h5 class="title">
                                <span class="center">{{tr('Favorite Order')}}</span>
                                <input type="text" class="form-control" v-model="FavOrder.Name" >
                            </h5>
                            <div v-if="message" class="alert alert-warning alert-dismissible fade show" role="alert">
                              <strong>{{tr('Information')}}:</strong> {{tr(message)}}
                              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>{{tr('Code')}}</th>
                                    <th>{{tr('Products')}}</th>
                                    <th>{{tr("Qty")}}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item of FavOrder.Items">
                                    <td>{{item.Code}}</td>
                                    <td>{{item.Name}}</td>
                                    <td>
                                        <input :id="'fav_ord_'+item.Code" type="number" class="form-control" min="1"  :max="getMaxStockByCode(item.Code)" :value="item.carQty" readonly>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                            <div class="text-right">
                               <button class="btn secondary-btn" data-dismiss="modal" @click="sendOrdertoCar"><i class="icon fas fa-cart-plus"></i>{{tr('Load to Cart')}}</button>
                               <button class="btn secondary-btn" data-dismiss="modal" @click="$store.commit('clearCurrentFavOrder')"><i class="icon fas fa-save"></i>{{tr('Close')}}</button>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }
}

favouriteOrderDetailModalComponent.registerComponent();